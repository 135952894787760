body {
  background-color: #1a001f;
  color: #ffd9fc;
  font-family: "Azeret Mono", monospace;
  padding: 0;
  margin: 0;
  position: relative;
}

header {
  width: 100%;
  height: 8em;
  margin: 0 0 25px 0;
  background: linear-gradient(0deg, rgba(109, 16, 101, 0), rgba(109, 16, 101, 0.8));
  z-index: 4;
  font-family: "Cutive", monospace;
}

main {
  float: none;
  margin-left: auto;
  margin-right: auto;
  margin-top: 15px;
  width: 80%;
  display: flex;
  flex-flow: row wrap;
  gap: 1em;
  justify-content: space-around;
  z-index: 1;
}

footer {
  float: none;
  margin-left: auto;
  margin-right: auto;
  height: 5em;
  text-align: center;
  background: linear-gradient(180deg, rgba(109, 16, 101, 0), rgba(109, 16, 101, 0.8));
}

section {
  box-sizing: border-box;
  padding: 15px;
  margin: 0px;
}

h1 {
  font-size: 4em;
  font-family: "VT323", monospace;
  text-align: center;
  margin: 0 1.5em 0.3em 1.5em;
  padding: 0.3em 0;
}

h2,
h3,
h4,
h5,
h6 {
  font-family: "Cutive", monospace;
}

p {
  margin: 1em 2em;
}

footer p {
  margin: 5px 0px 5px 0px;
  padding: 0.2em 0;
}

a {
  color: #eb72d7;
  position: relative;
  z-index: 5;
  text-decoration: none;
  font-style: italic;
}
a:hover {
  text-decoration: underline;
}

button {
  cursor: pointer;
  font-style: "Azeret Mono", monospace;
  height: -moz-fit-content;
  height: fit-content;
}

hr {
  color: #ffd9fc;
  background-color: #ffd9fc;
  height: 5px;
}

nav.site-nav {
  text-align: center;
  margin-bottom: 2em;
}
nav.site-nav .nav-link {
  padding: 9px 10px 8px 10px;
  margin: auto, 8px;
  border: solid 3px #e772ff;
  border-radius: 15px;
  text-decoration: none;
  font-style: normal;
  background-color: #2f0039;
  margin: 10px;
}
nav.site-nav .nav-link:hover {
  box-shadow: 3px 3px 5px #8f0c84;
}

.active {
  background-color: #6d1065;
  color: #ffd9fc;
}

.fx-link {
  padding: 9px 10px 8px 10px;
  margin: auto, 8px;
  border: solid 3px #e772ff;
  border-radius: 15px;
  text-decoration: none;
  font-style: normal;
  background-color: #2f0039;
  font-size: 0.8em;
  padding: 5px;
}

#fx {
  position: absolute;
  top: 2em;
  left: 2em;
}

#stars {
  background: 0;
  display: none;
  position: fixed;
  z-index: -3;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
}

.boxed {
  border: solid 3px #e772ff;
  padding: 0;
  z-index: 3;
  background-color: rgba(47, 0, 57, 0.7);
  max-height: 100%;
  min-height: -moz-fit-content;
  min-height: fit-content;
}

.box-title-holder {
  grid-area: title;
  border-bottom: solid 2px #e772ff;
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  width: 100%;
  max-height: -moz-fit-content;
  max-height: fit-content;
  background-color: #8f0c84;
  font-size: 1.2em;
}

.box-title {
  display: inline-block;
  line-height: auto;
  max-height: -moz-fit-content;
  max-height: fit-content;
  box-sizing: border-box;
  margin: 0;
  padding: 0 0 0 4px;
  background-color: #8f0c84;
  background: linear-gradient(90deg, #6d1065, #8f0c84);
}

.box-buttons {
  margin: 0;
  font-size: 1.5em;
  padding-right: 4px;
  background-color: #600859;
  height: 100%;
  font-family: sans-serif;
}
.box-buttons .min-button,
.box-buttons .max-button,
.box-buttons .x-button {
  padding-left: 0.5em;
  padding-right: 0.3em;
  border-left: solid 2px #e772ff;
  font-weight: 600;
}

.box-body {
  width: 100%;
  min-height: 100%;
  height: 100%;
}

.box-note {
  padding: 10px;
  margin: 10px 10px 40px auto;
  width: 90%;
  font-size: 1.4em;
  font-style: italic;
  text-align: center;
  background-color: rgba(47, 0, 57, 0.7);
  border-top: solid 10px #e772ff;
  z-index: 3;
}

.top-notes {
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
}

.small-head {
  font-size: 1.8em;
  margin: 5px 0px 10px 10px;
}

h3.inner {
  margin-left: 5px;
}

h4.quick-note {
  text-align: center;
  font-style: italic;
}

.social {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}
.social a {
  width: 100px;
}
.social a figure {
  margin: 10px 5px;
}
.social a figure figcaption {
  text-align: center;
}

#resume-link {
  display: block;
  text-align: center;
  margin-bottom: 5px;
  float: none;
  margin-left: auto;
  margin-right: auto;
}

figure.fit-picture {
  height: 500px;
  width: auto;
  max-width: 100%;
  margin: 0;
  float: none;
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-areas: "pic" "caption";
}
figure.fit-picture figcaption {
  box-sizing: border-box;
  height: 50px;
  width: 100%;
  z-index: 5;
  margin: 0px 10px;
  padding: 0.3em 10px;
  text-align: center;
  overflow-wrap: normal;
  grid-area: caption;
}
figure.fit-picture figcaption a,
figure.fit-picture figcaption p {
  margin: 0;
}

.slider-img {
  display: block;
  grid-area: pic;
  height: 450px;
  max-width: 100%;
  width: auto;
  -o-object-fit: scale-down;
     object-fit: scale-down;
  float: none;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  margin-top: 0;
}

figure.fit-picture#recent-webfig,
figure.fit-picture#single-pagefig,
figure.fit-picture#websitesfig {
  height: 400px;
}
figure.fit-picture#recent-webfig .slider-img,
figure.fit-picture#single-pagefig .slider-img,
figure.fit-picture#websitesfig .slider-img {
  height: 350px;
}

.slider-control {
  width: 90%;
  margin: 15px;
  float: none;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-self: end;
  color: #8f0c84;
}
.slider-control button {
  background-color: #8f0c84;
  border: 2px solid #eb72d7;
  border-radius: 5px;
  color: #ffd9fc;
  font-weight: 700;
  font-size: 1.2em;
  padding: 6px 6px 4px 6px;
  margin: 10px 15px;
}

.dots {
  display: flex;
  flex-flow: row wrap;
  max-width: 80%;
  justify-content: center;
  margin-top: 10px;
}
.dots .dot {
  font-size: 1em;
  cursor: pointer;
}
.dots .dot.activedot {
  color: #eb72d7;
}

.cover {
  max-height: 100%;
  min-width: 100%;
  width: 100%;
}

#portrait {
  box-sizing: border-box;
  float: left;
  width: 35%;
  margin: 10px;
}

.social-link figure img {
  display: block;
  max-width: 3em;
  height: 3em;
  float: none;
  margin-left: auto;
  margin-right: auto;
}

#promo {
  width: 500px;
  box-sizing: border-box;
  float: left;
  margin: 10px;
}

#introducing {
  flex-basis: 65%;
}

#recent-art {
  flex-basis: 33%;
}

#recent-web,
#homelinks,
#art-box,
#web-dev-box {
  flex-basis: 49%;
}

#homelinks {
  height: -moz-fit-content;
  height: fit-content;
}

#quickabout {
  padding: 10px;
  padding-left: 25px;
}

main#website-design {
  flex-flow: row wrap;
}

section#work,
section#examples {
  flex-basis: 60%;
  flex-grow: 0;
}

section.narrow-box {
  flex-basis: 38%;
}

div#skills-links {
  flex-basis: 38%;
  display: flex;
  flex-flow: column nowrap;
  gap: 1em;
  width: 100%;
}

#resume-link {
  display: block;
  padding: 0.2em;
  margin: 10px auto;
  text-align: center;
  font-size: 1.2em;
}

section#digital,
section#mixed-media {
  flex-basis: 49%;
}

.commission-top-note {
  grid-area: notes;
  box-sizing: border-box;
  width: 100%;
  margin: 10px;
  padding: 3px;
  line-height: 1.8em;
}

#commissions-top-stuff {
  display: grid;
  grid-template-columns: 60% 40%;
  grid-template-areas: "sheet notes";
  padding: 0;
  width: 70%;
  margin: 25px auto 10px auto;
  z-index: 4;
}

#comm-sheet {
  width: 95%;
  margin: auto;
  padding: 0px;
  grid-area: sheet;
  background-color: rgba(47, 0, 57, 0.7);
}
#comm-sheet img {
  box-sizing: border-box;
  width: 100%;
  margin: 0;
  border-top: solid 10px #e772ff;
}

#click-to-change,
.commission-top-links {
  display: inline-block;
  border: 2px solid #e772ff;
  border-radius: 8px;
  background-color: #8f0c84;
  color: #ffd9fc;
  font-size: 1em;
  font-style: italic;
  text-decoration: none;
  font-family: "Azeret Mono", monospace;
  padding: 3px;
  margin-top: 5px;
  margin-bottom: 5px;
}
#click-to-change:hover,
.commission-top-links:hover {
  text-decoration: none;
  background-color: #6d1065;
}

#commissions-main {
  display: flex;
  flex-flow: row wrap;
}

#process,
#left-side {
  flex-basis: 49%;
}

#left-side {
  display: flex;
  flex-flow: column nowrap;
  gap: 1em;
}

.inner-title {
  width: -moz-fit-content;
  width: fit-content;
  float: none;
  margin-left: auto;
  margin-right: auto;
}

.prompt-list {
  margin-left: 2em;
}

#inktober-container {
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: start;
  max-height: 100em;
}

#inktober-intro {
  width: 30%;
}

#inktober-calendar-box, #inktober-slider-box {
  width: 66%;
  height: -moz-fit-content;
  height: fit-content;
}
#inktober-calendar-box img, #inktober-slider-box img {
  width: 100%;
}

@media only screen and (max-width: 1500px) {
  .box-title-holder {
    font-size: 1em;
  }
  figure.fit-picture {
    height: 450px;
  }
  figure.fit-picture img.slider-img {
    height: 400px;
  }
  p.commission-top-note {
    font-size: 1.2em;
  }
}
@media only screen and (max-width: 1200px) {
  .box-title-holder {
    font-size: 0.8em;
  }
  #fx {
    top: 6em;
  }
  figure#recentfig {
    height: 400px;
  }
  figure#recentfig img.slider-img {
    height: 350px;
  }
  figure.fit-picture#recent-webfig,
  figure.fit-picture#single-pagefig,
  figure.fit-picture#websitesfig {
    height: 350px;
  }
  figure.fit-picture#recent-webfig img.slider-img,
  figure.fit-picture#single-pagefig img.slider-img,
  figure.fit-picture#websitesfig img.slider-img {
    height: 300px;
  }
  #recentslider-control {
    flex-flow: column nowrap;
    align-items: center;
  }
  .boxed#recent-art {
    height: -moz-fit-content;
    height: fit-content;
  }
  p.commission-top-note {
    font-size: 1em;
  }
  #inktober-intro,
  #inktober-calendar-box,
  #inktober-slider-box {
    width: 48%;
  }
  #inktober-container {
    max-height: 75em;
  }
}
@media only screen and (max-width: 1000px) {
  header {
    height: 4em;
  }
  h1 {
    font-size: 3em;
  }
  footer {
    font-size: 0.8em;
  }
  #fx {
    top: auto;
    bottom: 8em;
    font-size: 0.8em;
  }
  .fx-link {
    border-width: 2px;
  }
  nav.site-nav .nav-link {
    font-size: 0.8em;
    margin: 5px;
  }
  section#introducing,
  section#recent-art,
  section#recent-web {
    flex-basis: 100%;
  }
  section#homelinks {
    flex-basis: 70%;
  }
  #recentslider-control {
    flex-flow: row nowrap;
    align-items: center;
  }
  #promo {
    width: 300px;
  }
  section#mixed-media,
  section#digital {
    flex-basis: 80%;
    width: 100%;
  }
  #work {
    order: 0;
  }
  #certification {
    order: 2;
  }
  #examples {
    order: 1;
  }
  #skills-links {
    order: 3;
  }
  #about-site {
    order: 4;
    flex-basis: 50%;
  }
  div#skills-links,
  section#certification {
    flex-basis: 48%;
  }
  section#work,
  section#examples {
    flex-basis: 99%;
  }
  p.commission-top-note {
    font-size: 0.9em;
    line-height: 1.5;
  }
  #process,
  #left-side {
    flex-basis: 100%;
  }
  #contact {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
  }
  #inktober-intro,
  #inktober-calendar-box,
  #inktober-slider-box {
    width: 100%;
  }
  #inktober-container {
    max-height: -moz-fit-content;
    max-height: fit-content;
    flex-wrap: nowrap;
  }
}
@media only screen and (max-width: 820px) {
  body {
    font-size: 0.9em;
  }
  footer {
    height: 6em;
  }
  h1 {
    font-size: 2.5em;
  }
  h3.small-head {
    font-size: 1.4em;
  }
  h3.inner {
    font-size: 1.1em;
  }
  nav.site-nav {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
  }
  .box-title-holder {
    font-size: 0.7em;
  }
  .social-link figure img {
    height: 1.5em;
    max-width: 1.5em;
  }
  .social-link figure figcaption {
    font-size: 0.8em;
  }
  .social a {
    width: 75px;
  }
  div#fx {
    display: flex;
    flex-flow: row wrap;
    margin: 0 auto;
    bottom: 7em;
  }
  main {
    margin-bottom: 1.5em;
  }
  section#digital,
  section#mixed-media {
    flex-basis: 100%;
  }
  #commissions-top-stuff {
    display: flex;
    flex-flow: column nowrap;
  }
  .top-notes {
    flex-flow: row wrap;
  }
  .top-notes .commission-top-note {
    flex-basis: 45%;
  }
  #comm-sheet {
    width: 100%;
  }
}
@media only screen and (max-width: 630px) {
  header {
    height: 6em;
  }
  section#certification,
  div#skills-links {
    flex-basis: 98%;
  }
  section#about-site {
    flex-basis: 70%;
  }
}
@media only screen and (max-width: 500px) {
  .boxed {
    font-size: 0.9em;
  }
  figure.fit-picture {
    height: 300px;
  }
  figure.fit-picture img.slider-img {
    height: 250px;
  }
  figure.fit-picture#recent-webfig,
  figure.fit-picture#single-pagefig,
  figure.fit-picture#websitesfig {
    height: 250px;
  }
  figure.fit-picture#recent-webfig img.slider-img,
  figure.fit-picture#single-pagefig img.slider-img,
  figure.fit-picture#websitesfig img.slider-img {
    height: 200px;
  }
  .top-notes .commission-top-note {
    flex-basis: 80%;
  }
  #contact {
    width: 90%;
  }
}
@media only screen and (max-width: 420px) {
  header {
    height: 5.2em;
  }
  footer {
    height: 7em;
  }
  h1 {
    font-size: 2em;
  }
  .box-title-holder {
    font-size: 0.6em;
  }
  .slider-control,
  #recentslider-control {
    flex-flow: column nowrap;
    align-items: center;
  }
  section#about-site {
    flex-basis: 90%;
  }
}/*# sourceMappingURL=style.css.map */